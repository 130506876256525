<template>
  <v-container fluid style="margin-bottom: 120px">
    <v-row class="mt-10">
      <v-col cols="12">
        <h1 class="secondary--text">Elegibilidade</h1>
      </v-col>
    </v-row>
    <v-row class="align-center mt-10">
      <v-spacer />
      <v-col cols="12" md="1" sm="12" xs="12" class="justify-end align-end d-flex">
        <v-btn
          class="white--text"
          x-large
          block
          color="textPrimary"
          :disabled="!queryParameters.contractId"
          @click="openCreatedAndEditEligibility()"
        >
        <v-icon class="mr-5">
          fas fa-plus-circle
        </v-icon>
          Elegibilidade
        </v-btn>
      </v-col>
    </v-row>

    <v-row align-center>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="eligibilities"
          :page.sync="page"
          :items-per-page="10"
          hide-default-footer
          :loading="loadingTable"
          item-key="id"
          class="elevation-1"
        >
          <template v-slot:[`item.type`] = '{ item }'>
            <span> {{ convertNameBenefityType(item.type) }}</span>
          </template>
          <template v-slot:[`item.actions`] = '{ item }'>
            <v-btn icon color="primary" @click="openCreatedAndEditEligibility(item)">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn icon color="error" @click="openDialogDeleteEligibility(item)">
              <v-icon >mdi-delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>
        <div class="float-right pt-2">
          <v-pagination
            v-model="page"
            color="textPrimary"
            :length="totalPages"
            :total-visible="9"
            @input="getElibilityByContract(queryParameters.contractId)"
          />
        </div>
      </v-col>
      <v-col>
      <v-snackbar
        v-model="snackbar.show"
        timeout="10000"
        top
        elevation="24"
        :color="snackbar.type"
      >
        <div class="text-center">{{ snackbar.text }}</div>
        <template v-slot:action="{ attrs }">
          <v-icon
            text
            dark
            v-bind="attrs"
            @click="snackbar.show = false"
          >
            close
          </v-icon>
        </template>
      </v-snackbar>
      </v-col>

      <v-col cols="12" align="end">
        <v-footer>
          <v-row class="d-flex pt-6 justify-end">
            <v-btn
              x-large
              outlined
              color="textPrimary"
              width="250px"
              @click="onClickCancel()"
            >
              Voltar
            </v-btn>
          </v-row>
        </v-footer>
      </v-col>
    </v-row>

    <v-dialog v-model="dialogDeleteEligibility" max-width="700px">
      <v-card class="pa-5">
        <v-card-title class="text-h5 justify-center primary--text">
          Você tem certeza que deseja excluir essa elegibilidade?
        </v-card-title>
        <v-card-actions>
        <v-row class="mt-6">
          <v-spacer></v-spacer>
          <v-col cols="12" xs="12" sm="12" md="3" lg="3">
            <v-btn
              color="#3B495B"
              outlined
              block
              @click="closeDialogDeleteEligibility()"
            >
              Cancelar
            </v-btn>
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="3" lg="3">
            <v-btn
              color="#3B495B"
              block
              style="color: white"
              @click="confirmationDeleteEligibility()"
            >
              Confirmar
            </v-btn>
          </v-col>
        </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import EligibilityService from '@/services-http/sdi/EligibilityService';
import FinancialGroupService from '@/services-http/contract/FinancialGroupService';

export default {
  name: 'ElegibilityConfig',
  data: () => ({
    page: 1,
    totalPages: 0,
    isValidForm: true,
    loadingTable: false,
    dialogDeleteEligibility: false,
    eligibilitiesToDelete: [],
    eligibilities: [],
    snackbar: {
      show: false,
      type: '',
      text: '',
    },
    queryParameters: {
      token: null,
      contractId: null,
    },
    headers: [
      {
        text: 'Nome',
        value: 'name',
        align: 'center',
        cols: '12',
      },
      {
        text: 'Tipo',
        value: 'type',
        align: 'center',
        cols: '6',
      },
      {
        text: 'Ações',
        value: 'actions',
        align: 'center',
        cols: '6',
      },
      {
        text: ' ',
        align: 'right',
        value: 'data-table-expand',
      },
    ],
  }),

  mounted() {
    this.loadQueryParameters();
  },

  methods: {
    loadQueryParameters() {
      const { contractId, token } = this.$route.query;
      this.queryParameters.contractId = contractId;
      this.queryParameters.token = token;
      if (this.queryParameters.contractId) {
        this.saveOdooToken();
        this.getElibilityByContract(this.queryParameters.contractId);
      } else {
        this.customizeSnackbarMessage('error', 'O Id do contrato não foi informado');
      }
    },
    saveOdooToken() {
      if (this.queryParameters && this.queryParameters.token) {
        sessionStorage.setItem('@auth-odoo/token', `Bearer ${this.queryParameters.token}`);
      }
    },
    async getElibilityByContract(item) {
      this.loadingTable = true;
      const contractId = item;
      this.eligibilityService.FindAllByContractId({ contractId, page: this.page - 1, size: 10 }).then((response) => {
        if (response && response.data.content) {
          this.totalPages = response.data.totalPages;
          this.eligibilities = response.data.content;
        }
        this.loadingTable = false;
      }).catch((error) => {
        this.loadingTable = false;
      });
    },
    convertNameBenefityType(item) {
      let text = '';
      if (item === 'INSURANCE_CARRIER_DEFINITION') {
        text = 'Definição Corretora';
      }
      if (item === 'VALIDATION') {
        text = 'Validação';
      }
      return text;
    },
    openCreatedAndEditEligibility(item) {
      if (item) {
        this.$router.push({ name: 'EligibilityCreatedAndUpdate', query: { id: item.id, contractId: this.queryParameters.contractId, method: 'isEdit' } });
      } else {
        this.$router.push({ name: 'EligibilityCreatedAndUpdate', query: { contractId: this.queryParameters.contractId, method: 'isNew' } });
      }
    },
    openDialogDeleteEligibility(item) {
      this.eligibilitiesToDelete.push({ id: item.id });
      this.dialogDeleteEligibility = true;
    },
    closeDialogDeleteEligibility() {
      this.dialogDeleteEligibility = false;
      this.eligibilitiesToDelete.pop();
    },
    async confirmationDeleteEligibility() {
      this.loadingTable = true;
      if (this.eligibilitiesToDelete.length) {
        const ids = this.eligibilitiesToDelete.map(
          (eligibility) => eligibility.id,
        );
        await this.eligibilityService.DeleteByIds(ids).then((response) => {
          if (response) {
            this.loadQueryParameters();
          }
          this.closeDialogDeleteEligibility();
          this.customizeSnackbarMessage('success', 'Elegibilidade deletada com sucesso!');
          this.loadingTable = false;
        });
      }
    },
    onClickCancel() {
      this.$router.push({ name: 'HomeAllMenus' });
    },
    customizeSnackbarMessage(type, text) {
      this.snackbar.show = true;
      this.snackbar.type = type;
      this.snackbar.text = text;
    },
  },

  async created() {
    this.eligibilityService = new EligibilityService();
    this.financialGroupService = new FinancialGroupService();
  },
};
</script>

<style scoped>
.my-tile {
  margin-bottom: 25px !important;
}
</style>
